import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Loadable from "@loadable/component"

const AboutUsPage = ({data}) => {

    const OfficeImage = Loadable(() => import("../components/officeimage"));

    return(

        <Layout pageClass='about-us-page'>
        <SEO title="About Us" />
            <main className='main'>
                <div className='typography inner'>
                    <h1>About Us</h1>

                    <OfficeImage />

                    <p>With <span className="red bold">22 YEARS</span> Coding experience in the <span className="red bold">New Zealand FMCG</span> and <span className="red bold">Australian FMCG</span>, <span className="blue bold">Manufacturing</span>, <span className="blue bold">Distribution</span> and <span className="blue bold">Processing</span> markets, we manufacture and supply the complete range of coding equipment and consumables for <span className="red bold">all makes and models</span> of thermal label printing equipment, off-line and online, industrial ink jets coders, label applicators and accessories.
                    </p>
                    <p>
                    In conjunction with being able to supply you tailor-made consumables, self-adhesive labels; thermal ribbons and inks we also offer you a range of printing and coding equipment that will meet the requirements of most if not all small, medium or large coding projects.
                    </p>
                    <p>
                    To finish all these tailor-made consumable and machine solutions we also offer you Technical Support and training on our own and other makes of coding equipment and software.
                    </p>
                    <h3><span className="red">OUR GOAL</span></h3>
                    <p></p>
                    <p>
                        Our goal is to significantly <span className="red bold">reduce</span> your marking and coding costs whilst supplying the <span className="red bold">most efficient and cost-effective</span> coding solution that suits your application and requirements perfectly! We endeavour to provide the highest quality products tailor made to your specific requirements at <span className="red bold">highly competitive prices</span> – secure in the knowledge that they are fully compatible with the original products regardless of make and model of your equipment.
                    </p>
                </div>
            </main> 
        </Layout>

    )
}

export const query = graphql`
  query {
    imageSharp(resolutions: {originalName: {eq: "Office-Sign-AU-crop.jpg"}}) {
        resize(width: 600, quality: 100) {
            src
          }
        tablet: resize(width:1000, height: 250, quality: 90) {
            src
        }
        mobile: resize(width: 460, height: 200, quality: 90) {
            src
        }
        
      }
  }
`

export default AboutUsPage